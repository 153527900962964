#DonateButtonContainer {
  background-color: #29292e;
  padding: 30px;
  border-radius: 0 0 15px 15px;
  border: 2px solid #2f2f2f;
  border-top: 0;
}

#DonateButton {
  cursor: pointer;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 30px;
  color: white;
  background-color: #4ccfff;
  background-image: linear-gradient(to bottom, #4ccfff, #38a3ba);
  border: none;
  border-radius: 15px;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
}

#DonateButton:hover {
  background-color: #2f8fb3;
  background-image: linear-gradient(to bottom, #2b9bc4, #10778f);
  color: rgb(167, 167, 167);
}

#MinimumDonation {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.479);
  margin-top: 30px;
}