.TwitchButton {
    cursor: pointer;
    width: 350px;
    height: 100px;
    font-size: 2.5rem;
    color: white;
    background-color: #6441a5;
    border: none;
    border-radius: 5px;
    font-family: 'Dosis', sans-serif;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    padding: 0;
}

.TwitchButton .Icon {
    flex: 1;
    height: 100%;
    border-radius: 5px 0 0 5px;
    background-color: #56388e;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.TwitchButton .Icon img {
    height: 60%;
}

.TwitchButton .Text {
    flex: 2;
}