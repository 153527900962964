#SupportingBanner {
    font-size: 50px;
    border-radius: 15px 15px 0 0;
    background-color: #ffa619;
    display: flex;
    align-items: center;
    justify-items: space-around;
    border: 2px solid #ffb33a;
    border-bottom: 0;
}

#text {
    padding: 20px;
    text-align: left;
    flex: 3;
    min-width: 40%;
}

#supporting {
    color: rgba(255, 255, 255, 0.562);
    font-weight: bold;
    font-size: 32px;
}

#causeName {
    font-size: 72px;
    font-weight: bold;
    line-height: 60px;
    word-wrap: break-word;
}

#logo {
    padding: 20px;
    flex: 1;
    text-align: center;
}

#logo img {
    border-radius: 50%;
    height: 120px;
    vertical-align: middle;
}