#InfoBox {
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    background-color: #393e44;
    padding: 40px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.774);
    border: 2px solid #505050;
    border-top: 0;
    border-bottom: 0;
}

.text {
    margin-left: 20px;
}

a {
    font-weight: bold;
    color: white;
    font-style: underline;
}

.point {
    height: 15px;
}

#YellowBox {
    padding: 20px;
    border-radius: 5px;
    margin-top: 30px;
    color: white;
    font-family:'Dosis', sans-serif;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    border: 2px solid #ffa619;
}