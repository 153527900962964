body {
  margin: 0;
  font-family:'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  text-align: center;
  background-image: url("/background.png");
  background-size: 70px 70px;
}

#container {
  width: 650px;
  text-align: center;
  display: inline-block;
}