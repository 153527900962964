#NotLive {
    padding: 50px;
    font-size: 1rem;
    background-color: #29292e;
    border-radius: 15px;
    border: 2px solid #2f2f2f;
    border-top: 0;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

#NotLive .Header {
    text-align: center;
    font-size: 2rem;
    font-family:'Dosis', sans-serif;
    font-weight: bold;
    margin-bottom: 15px;
}

#NotLive .TwitchButtonContainer {
    text-align: center;
    margin-top: 40px;
}