#FaqBox {
    margin-top: 50px;
}

#FaqBox #Title {
    border: 2px solid #67cdf0;
    border-radius: 15px 15px 0 0;
    background-color: #49c9f5;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding: 20px;
}

#FaqBox #Body {
    padding: 30px;
    font-size: 1rem;
    background-color: #29292e;
    border-radius: 0px 0px 15px 15px;
    border: 2px solid #2f2f2f;
    border-top: 0;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

#FaqBox video {
    width: 100%;
    border-radius: 15px;
    margin-top: 30px;
}

#FaqBox .header {
    text-align: center;
    font-size: 1.5rem;
    font-family:'Dosis', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 60px;
}

#FaqBox .Image {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

#FaqBox .Image img {
    height: 300px;
}

#FaqBox #Me {
    margin-bottom: 0;
}